.footerContainer ul{
    padding-left: 0;
    list-style: none;
}

.footerContainer{
    padding: 0 100px;
    background-color: #f2f5fa;
    padding-top: 50px;
    padding-bottom: 30px;
}

.footerContainer img{
    width: 100px;
}

.footerContainer p {
    font-size: 19px;
    font-weight: 600;
}

.footerContainer h6{
    font-size: 21px;
    font-weight: 800;
}


.footerContainer a {
    color: #000000;
    text-decoration: none;
    text-align: right;
  }

 .footerContainer a:after {
    content: '';
    display: block;
    margin: auto;
    margin-left: 0;
    height: 1px;
    width: 0px;
    background: transparent;
    transition: width .3s ease, background-color .3s ease;
  }
 .footerContainer a:hover:after {
    width: 100%;
    background: rgb(0, 0, 0);
  }

  .footerContainer .footerBottom{
    border-top: 1px solid gray;
  }

  .footerContainer .footerBottom p{
    font-size: 16px;
    font-weight: 400;
  }

  .justifyManul{
    justify-content: end;
  }

  @media only screen and (max-width: 768px) {
    .footerContainer{
      padding: 0 40px;
    }

    .justifyManul{
      justify-content: start;
    }
  }