.bg-light{
    background-color: red !important;
}

.headerMenu {
  position: relative;
}
.headerMenu .active{
    color: #000000 !important;
 }
 
.headerMenu .NavLinkCus{
    color: #000000;
    text-decoration: none;
    font-family: "Zain", sans-serif !important;
    font-size: 20px;
}

.headerMenu a:hover {
    color: #000000;
  }

 .headerMenu a:after {
    content: '';
    display: block;
    margin: auto;
    margin-left: 0;
    height: 1px;
    width: 0px;
    background: transparent;
    transition: width .3s ease, background-color .3s ease;
  }
 .headerMenu a:hover:after {
    width: 100%;
    background: rgb(0, 0, 0);
  }

  .headerMenu .productsMenuDropDown{
    width: 100%;
    background-color: red;
    position: absolute;
    top: 138px;
    height: fit-content;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 999;
    padding: 35px 70px;
  }
   
  .headerMenu .productsMenuDropDown .headingDiv{
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px;
  }

  .headerMenu .productsMenuDropDown .headingDiv h4{
    text-transform: capitalize;
    font-size: 19px !important;
    color: #1e1e1e;
    cursor: pointer;
  }

  .headerMenu .productsMenuDropDown .headingDiv h4:hover{
color: rgb(45, 45, 129);
  }

  .headerMenu .productsMenuDropDown .patternsDiv{

  }

  .headerMenu .productsMenuDropDown .patternsDiv p{
    text-transform: capitalize;
    font-size: 17px;
    cursor: pointer;
  }

  .headerMenu .productsMenuDropDown .patternsDiv p:hover{
   color: rgb(45, 45, 129);
  }


  .carrot{
    width: 68px;
    background: rgba(255, 255, 255, 0);
    display: block;
    height: 44px;
    position: absolute;
    left: 56%;
    top: -38px;
  }

  .contactPanel{
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;

  }

  .contactPanel h6{
    font-size: 18px;
    color: #5f584c;
    font-weight: 800;
  }

   .contactPanel h6 {
    text-align: right;
  
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    
    -moz-animation: my-animation 15s linear infinite;
    -webkit-animation: my-animation 15s linear infinite;
    animation: my-animation 15s linear infinite;
  }
  
  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(100%); }
    to { -moz-transform: translateX(-100%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(100%); }
    to { -webkit-transform: translateX(-100%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }