.CategoriesList{
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
}

.CategoryDiv{
    width: 170px;
}

.CategoryImg{
    width: 170px;
    height: 170px;
    border-radius: 50%;
    box-shadow: 0px 0px 11px -7px gray;
}

.CategoryImg img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
}

.CategoryDiv p{
    font-size: 20;
}

.catrgoriesContainer{
    margin-top: 100px;
    margin-bottom: 80px;
}

.catrgoriesContainer .ml-0{
    margin-left: 0 !important;
}

.catrgoriesContainer .mr-3{
    margin-right: 1rem !important;
}

.catrgoriesContainer .categoryName{
    text-transform: capitalize;
    font-size: 19px;
    font-weight: 500;
}