.filter{
    border: 1px solid #adb7c0;
    border-radius: 6px;
    height: fit-content;
}

.borderBottom{
    border-bottom: 1px solid #adb7c0;
}
.allProducts .FreshProductDivImg img{
    height: 338px !important;
}

.allProducts .productDiv p{
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}

.productDiv{
    cursor: pointer;
}

.productDetailsDiv h1{
    text-transform: capitalize;
    font-size: 38px;
    font-weight: 800;
}

.productDetailsDiv h6{
    font-size: 16px;
    font-weight: 400;
}

.allProducts h6{
    font-size: 20px;
    font-weight: 600;
}

.productDetailsDiv p{
    text-transform: capitalize;
    font-weight: 600;
    font-size: 17px;
}

.colorNameP{
    font-size: 15px !important;
 }

.colorDemoDiv{
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0px 1px 10px 1px #c2c2c2;
}

.colorDemoDiv img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.pdHeading{
    font-weight: 800;
    font-size: 17px;
}

.whatsappOption{
        background: #4baf50;
       border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      padding: 3px;
      box-shadow: 0px 1px 10px 1px #c2c2c2;
   }
  
  .whatsappOption svg{
    width: 38px;
    height: 38px;
  }

a:link{
    text-decoration: none;
    border-bottom: none;
}

@media only screen and (max-width: 768px) {
    .allProducts .FreshProductDivImg img {
        height: 270px !important;
    }
}