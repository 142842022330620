.ShopByPatternList{
 }

.ShopByPatternContainer{
 }

 .ShopByPatternDivImg{
    height: 345px;
 }

 .ShopByPatternDivImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
 }

 .ShopByPatternItems .linkDiv {
    background-color: #f2f5fa;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    text-transform: capitalize;
    font-size: 17px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .patternProductName{
   text-transform: capitalize;
   font-size: 20px;
   font-weight: 600;
 }

 .ShopByPatternItems a{
color: black;
text-decoration: none;
font-size: 19px;
  }

 .linkDiv a:after {
   content: '';
   display: block;
   margin: auto;
   margin-left: 0;
   height: 1px;
   width: 0px;
   background: transparent;
   transition: width .3s ease, background-color .3s ease;
 }
 .linkDiv a:hover:after {
   width: 100%;
   background: rgb(0, 0, 0);
 }

 .linkDiv p {
   width: fit-content;
 }

 .bigImage{
    width: 100%;
    height: 900px;
    position: relative;
 }

 .bigImage .absoluteDiv{
    z-index: 1;
 }
 .bigImage img{
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    height: 100%;
 }

 .bigImage h1{
   font-weight: 900;
   text-transform: capitalize;
   color: white;
 }

 .nwColBtnMt{
   margin-top: 260px;
 }

 .ShopByPatternListIMG img{
   object-fit: cover !important;
 }

 @media only screen and (max-width: 768px) {
   .ShopByPatternList{
      overflow-x: scroll;
   }

   .mobileScroll{
      overflow-x: scroll;
   }

   .bigImage{
      height: 442px;
   }
 }

 .FreshProductDivImgPattern img{
   height: 300px !important;
 }