.testimonialImg{
    width: 120px;
height: 180px;
}

.testimonialImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

svg{
    width: 20px;
}

.ratingMainDiv .react-multi-carousel-list{
    padding-bottom: 100px;
}
.ratingMainDiv .react-multiple-carousel__arrow{
    bottom: 0;
}


.ratingMainDiv .react-multi-carousel-list button:nth-child(2) {
   left: 45%;
  }

 .ratingMainDiv .react-multi-carousel-list button:nth-child(3) {
    right: 45%;
   }

.ratingMainDiv .react-multiple-carousel__arrow{
    max-width: 43px !important;
    max-height: 43px !important;
    }

.ratingMainDiv h1{
    font-weight: 900;
}    

.ratingMainDiv .headingP{
    font-size: 19px;
    font-weight: 500;
}

.ratingMainDiv .reviewInfo h5{
font-size: 24px;
font-weight: 700;
text-transform: capitalize;
}

.ratingMainDiv .reviewInfo h6{
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    }

.ratingMainDiv .reviewInfo .productName {
        font-size: 17px;
        text-transform: capitalize;
    }

.ratingMainDiv .reviewInfo .date{
    font-size: 16px;
}

.ratingMainDiv .reviewInfo .review{
    font-size: 18px;
    text-transform: capitalize;
height: 58px;
width: 200px;
/* white-space: nowrap;  */
   
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .ratingMainDiv .react-multi-carousel-list button:nth-child(2){
        left: 30%;
    }

    .ratingMainDiv .react-multi-carousel-list button:nth-child(3){
        right: 30%;
    }
}