.NewsletterContainer{
    background-color: #f2f5fa;
    width: 70%;
    margin: auto;
    margin-top: 90px !important;
    margin-bottom: 80px !important;
    border-radius: 20px;
    position: relative;
}

.NewsletterContainer p{
    width: 70%;
    margin: auto;
    text-align: center;
    font-size: 22px;
}

.NewsletterContainer h5{
    font-size: 38px;
    font-weight: 900;
}

.NewsletterContainer .emailJoinDiv{
    width: 420px;
    position: absolute;
    bottom: -20px;
    left: 32%;
}

@media only screen and (max-width: 768px) {
    .NewsletterContainer{
        width: 80%;
    }

    .NewsletterContainer p{
        width: 90%;
    }
    .NewsletterContainer .emailJoinDiv{
        left: -10%;
    }
}