.carousel-caption{
    bottom: 3rem !important;
}

.carousel-inner{
    height: 640px;
}

.carousel-item{
    height: 640px;
}

.bannerImagContainer{
    height: 100%;
}
.carousel{
    height: 640px;
}

.mainSlider a:hover{
    text-decoration: none ;
}

.mainSlider a:hover:after {
    width: 0;
}

.mainSlider a:after {
    display: none;
}

.carousel-indicators [data-bs-target]{
 width: 10px !important;
 height: 10px !important;
 border-radius: 50%;   
}

.bannerImagContainer img{
    height: 100%;
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
}


@media only screen and (max-width: 768px) {
    .carousel-item{
        height: 520px;
    }

    .carousel-inner {
        height: 520px;
    }

    .carousel {
        height: 520px;
    }

    .catrgoriesContainer{
        margin-top: 54px;
    }
  }