/* @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&family=Zain:wght@200;300;400;700;800;900&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-right-nav, .image-gallery-left-nav, .image-gallery-play-button {
  display: none !important;
}

.image-gallery-slide .image-gallery-image{
  object-fit: contain !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image{
  min-height: 550px !important;
  max-height: 550px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image{
  height: 85px;
  object-fit: cover;
  border-radius: 2px;
}

.image-gallery-thumbnail.active{
  border: 2px solid #363636;
  border-radius: 4px;
}

.image-gallery-thumbnail:hover{
  border: 2px solid #363636;
  border-radius: 4px;
}

.image-gallery-slides{
  border-radius: 8px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg-light{
  background-color: red !important;
}

p{
  margin-bottom: 0 !important;
}

label{
  font-family:"Zain", sans-serif !important;
  font-weight: 500;
  font-size: 18px;
}

button{
  font-family:"Zain", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

h1,h2,h3,h4,h5,h6{
  margin-bottom: 0 !important;
  font-family:"Zain", sans-serif !important;
}

h4{
  font-size: 32px !important;
  font-weight: 800 !important;
}

p{
  font-family:"Zain", sans-serif !important;
}

.spacingContainer{
  padding: 0 28px;
}

.displayManul{
  display: flex;
}

.contactMainDiv{
  width: 40%;
  margin: auto;
}

.aboutUsPageDiv{
  width: 50%;
  margin: auto;
}

.aboutUsPageDiv h3{
  font-weight: 700;
}

.aboutUsPageDiv p{
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .aboutUsPageDiv{
    width: 90%;
  }

  .displayManul{
    display: block;
  }

  .mt-md-4{
    margin-top: 3rem!important;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image{
    min-height: 350px !important;
    max-height: 350px !important;
  }
}

.whatsappFab{
  position: fixed;
     background: #4baf50;
    bottom: 12%;
    right: 5%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 3px;
    z-index: 99999;
}

.whatsappFab svg{
  width: 38px;
  height: 38px;
}