.FreshProductDiv{
    width: 310px;
    cursor: pointer;
}

.FreshProductDivImg{
    width: 310px;
    box-shadow: 0px 0px 14px -10px rgb(182, 182, 182);
    border-radius: 15px;
}

.FreshProductDivImg img{
    width: 100%;
    height: 442px;
    object-fit: cover;
    border-radius: 15px;
}

.freshSizeSpn{
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.FreshProductsContainer{
    position: relative;
}

.FreshProductsContainer h4{
    position: absolute;
    top: 8px;
}

.FreshProductsContainer .react-multi-carousel-list{
    padding-bottom: 0;
    padding-top: 60px;
}
.FreshProductsContainer .react-multiple-carousel__arrow{
    top: 0;
}


.FreshProductsContainer .react-multi-carousel-list button:nth-child(2) {
   left: 90%;
  }

 .FreshProductsContainer .react-multi-carousel-list button:nth-child(3) {
    right: 2%;
   }

.FreshProductsContainer .react-multiple-carousel__arrow{
    max-width: 43px !important;
    max-height: 43px !important;
    }

    .FreshProductsContainer   .freshProductName{
        text-transform: capitalize;
    font-size: 19px;
    font-weight: 400;
    }

    @media only screen and (max-width: 768px) {
        .FreshProductsContainer .react-multi-carousel-list button:nth-child(2) {
            left: 73%;
        }
        .react-multi-carousel-item{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }